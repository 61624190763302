<!--
 * @Description: 费用类型
 * @Author: 琢磨先生
 * @Date: 2022-05-18 23:15:49
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-01-03 14:51:41
-->


<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table
      v-loading="loading"
      :data="tableData"
      :border="true"
      row-key="id"
      default-expand-all
    >
      <el-table-column label="id" prop="id" width="80"></el-table-column>
      <el-table-column label="名称" prop="name">
      </el-table-column>
      <el-table-column label="单位" prop="unit" width="180"></el-table-column>
      <el-table-column label="停用" width="180">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="描述"
        prop="description"
        width="500"
      ></el-table-column> -->
      <el-table-column
        label="创建时间"
        prop="create_at"
        width="200"
      ></el-table-column>
      <el-table-column label="操作" width="140">
        <template #default="scope">
          <el-button
            type="primary"
            size="small" link
            @click="onEdit(scope.row)"
            v-if="!scope.row.is_system"
            >修改</el-button
          >
          <el-popconfirm
            title="确定要删除？"
            @confirm="onDelete(scope.row)"
            v-if="!scope.row.is_system"
          >
            <template #reference>
              <el-button type="danger" link size="small" 
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="16">
          <el-input
            v-model="form.name"
            placeholder="请输入费用项目名称"
          ></el-input>
        </el-col>
        <div class="help-block">使用后名称无法修改</div>
      </el-form-item>
      <el-form-item label="单位">
        <el-col :span="10">
          <el-input v-model="form.unit" placeholder=""></el-input>
        </el-col>
        <div class="help-block">单位、例如：元/次、元/天等。没有单位则不填</div>
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      saving: false,
      visibleDialog: false,
      title: "",
      tableData: [],
      form: {},
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.post(`seller/v1/expense`).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

    /**
     *
     */
    onEdit(item) {
      this.title = item ? "修改" : "新增";
      this.form = {};
      this.form = Object.assign({}, item);
      this.visibleDialog = true;
    },

    /**
     *
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/expense/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.loadData();
                this.visibleDialog = false;
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("/seller/v1/expense/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>

<style scoped>
</style>